/* global API_AUTH_ROOT, API_ROOT */
import { createAction } from 'redux-actions'
import _ from 'lodash'
import { createReducer } from 'reduxsauce'
import { types as checkoutTypes } from 'reduxModules/checkout'
import { types as subscriptionTypes } from 'reduxModules/subscriptions'
import { types as userTypes } from 'reduxModules/users'
import { types as verifyTypes } from 'reduxModules/emailConfirmation'
import { types as hijackTypes } from 'reduxModules/hijack'
import { fetchHelper } from '../utils/fetch'
import types from './types'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const authStatus = {
  initial: types.AUTH_INITIAL
}

export const loginStatus = {
  success: types.AUTH_LOGIN_SUCCESS,
  error: types.AUTH_LOGIN_FAILURE
}

export const logoutStatus = {
  success: types.AUTH_LOGOUT_SUCCESS,
  error: types.AUTH_LOGOUT_FAILURE
}

export const signUpStatus = {
  success: types.AUTH_SIGNUP_SUCCESS,
  error: types.AUTH_SIGNUP_FAILURE
}

export const facebookLoginStatus = {
  success: types.AUTH_FACEBOOK_LOGIN_SUCCESS,
  error: types.AUTH_FACEBOOK_LOGIN_FAILURE
}

export const microsoftLoginStatus = {
  success: types.AUTH_MICROSOFT_LOGIN_SUCCESS,
  error: types.AUTH_MICROSOFT_LOGIN_FAILURE
}

export const resetPasswordStatus = {
  success: types.AUTH_RESET_PASSWORD_SUCCESS,
  error: types.AUTH_RESET_PASSWORD_FAILURE
}

export const changePasswordStatus = {
  success: types.AUTH_CHANGE_PASSWORD_SUCCESS,
  error: types.AUTH_CHANGE_PASSWORD_FAILURE
}

export const forgotPasswordStatus = {
  success: types.AUTH_FORGOT_PASSWORD_SUCCESS,
  error: types.AUTH_FORGOT_PASSWORD_FAILURE
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: authStatus.initial,
  token: '',
  email: '',
  firstName: null,
  lastName: null,
  usedFreeTrial: false,
  subscription: null,
  isStaff: false,
  isSuperuser: false,
  isArtist: false,
  hijackerUser: {},
  hijackerToken: null,
  role: null,
  loggedInFromEmail: false,
  canGiftSubscription: false,
  couldGiftSubscription: false,
  createdByProxy: false
}

export const verifySuccess = (state = INITIAL_STATE) => ({
  ...state,
  isVerified: true
})

export const loginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload.user,
  token: action.payload.token,
  loggedInFromEmail: false,
  status: loginStatus.success
})

export const loginFromEmailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  token: action.payload,
  loggedInFromEmail: true,
  status: loginStatus.success
})

export const fetchUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload,
  status: loginStatus.success
})

export const loginFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: loginStatus.error
})

export const logoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE
})

export const logoutFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: logoutStatus.error
})

export const signUpSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload.user,
  token: action.payload.token,
  status: signUpStatus.success
})

export const signUpFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: signUpStatus.error
})

export const facebookLoginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload.user,
  token: action.payload.token,
  status: facebookLoginStatus.success
})

export const facebookLoginFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: facebookLoginStatus.error
})

export const microsoftLoginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload.user,
  token: action.payload.token,
  status: microsoftLoginStatus.success
})

export const microsoftLoginFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: microsoftLoginStatus.error
})

export const resetPasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: resetPasswordStatus.error
})

export const changePasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: changePasswordStatus.error
})

export const forgotPasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: forgotPasswordStatus.error
})

export const removeSubscriberAccessSuccess = (state = INITIAL_STATE) => ({
  ...state,
  usedFreeTrial: true,
  subscription: null
})

export const subscribeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  subscription: action.payload
})

export const addVideoSubscription = (state = INITIAL_STATE, action) => {
  const { monthlyVideosSeen } = state.subscription

  if (_.isUndefined(_.find(monthlyVideosSeen, { id: action.payload.id })))
    return {
      ...state,
      subscription: {
        ...state.subscription,
        monthlyVideosSeen: [...(monthlyVideosSeen || []), action.payload]
      }
    }
  return { ...state }
}

export const subscribeCancellationPendingSuccess = (state = INITIAL_STATE) => ({
  ...state,
  subscription: {
    ...state.subscription,
    pendingChange: true
  }
})

export const hijackTokenSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload.hijacked.user,
  token: action.payload.hijacked.token,
  hijackerUser: {
    email: state.email,
    firstName: state.firstName,
    lastName: state.lastName,
    usedFreeTrial: false,
    subscription: state.subscription,
    isStaff: state.isStaff,
    isSuperuser: state.isSuperuser,
    isArtist: state.isArtist
  },
  hijackerToken: action.payload.hijackerToken
})

export const releaseHijacked = (state = INITIAL_STATE) => ({
  ...state,
  ...state.hijackerUser,
  token: state.hijackerToken,
  hijackerUser: INITIAL_STATE.hijackerUser,
  hijackerToken: INITIAL_STATE.hijackerToken
})

export const revokeGiftSubscription = (state = INITIAL_STATE) => ({
  ...state,
  canGiftSubscription: false
})

export const revokeFreeTrial = (state = INITIAL_STATE) => ({
  ...state,
  usedFreeTrial: true
})

export const giftAvailabilityUpdate = (state = INITIAL_STATE) => ({
  ...state,
  canGiftSubscription: true,
  couldGiftSubscription: false
})

export const updateAuthFromProfileSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  firstName: action.payload?.firstName,
  lastName: action.payload?.lastName
})

export const HANDLERS = {
  [types.AUTH_LOGIN_SUCCESS]: loginSuccess,
  [types.AUTH_FROM_EMAIL]: loginFromEmailSuccess,
  [types.AUTH_LOGIN_REQUEST_FAILURE]: loginFailure,
  [types.AUTH_SIGNUP_SUCCESS]: signUpSuccess,
  [types.AUTH_SIGNUP_REQUEST_FAILURE]: signUpFailure,
  [types.AUTH_FACEBOOK_LOGIN_SUCCESS]: facebookLoginSuccess,
  [types.AUTH_FACEBOOK_LOGIN_REQUEST_FAILURE]: facebookLoginFailure,
  [types.AUTH_MICROSOFT_LOGIN_SUCCESS]: microsoftLoginSuccess,
  [types.AUTH_MICROSOFT_LOGIN_REQUEST_FAILURE]: microsoftLoginFailure,
  [types.AUTH_RESET_PASSWORD_REQUEST_FAILURE]: resetPasswordFailure,
  [types.AUTH_CHANGE_PASSWORD_REQUEST_FAILURE]: changePasswordFailure,
  [types.AUTH_FORGOT_PASSWORD_REQUEST_FAILURE]: forgotPasswordFailure,
  [types.AUTH_INVALID]: logoutSuccess,
  [types.AUTH_LOGOUT_SUCCESS]: logoutSuccess,
  [types.AUTH_ACCOUNT_DELETE_SUCCESS]: logoutSuccess,
  [types.AUTH_LOGOUT_FAILURE]: logoutFailure,
  [types.REMOVE_SUBSCRIPTION]: removeSubscriberAccessSuccess,
  [types.ADD_VIDEO_SEEN]: addVideoSubscription,
  [types.AUTH_FETCH_USER_SUCCESS]: fetchUserSuccess,
  [types.SUBSCRIPTION_CANCELLATION_PENDING]: subscribeCancellationPendingSuccess,
  [subscriptionTypes.SUBSCRIPTION_SUMMARY_FETCH_SUCCESS]: subscribeSuccess,
  [checkoutTypes.SUBSCRIBE_SUCCESS]: subscribeSuccess,
  [userTypes.USERS_CHANGE_PLAN_SUCCESS]: subscribeSuccess,
  [hijackTypes.HIJACK_FETCH_SUCCESS]: hijackTokenSuccess,
  [hijackTypes.HIJACK_RELEASE]: releaseHijacked,
  [types.REVOKE_GIFT_SUBSCRIPTION]: revokeGiftSubscription,
  [types.REVOKE_FREE_TRIAL]: revokeFreeTrial,
  [types.UPDATE_GIFT_AVAILABILITY]: giftAvailabilityUpdate,
  [verifyTypes.VERIFY_REQUEST_SUCCESS]: verifySuccess,
  [userTypes.USERS_PROFILE_UPDATE_SUCCESS]: updateAuthFromProfileSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const loginRequestSuccess = createAction(types.AUTH_LOGIN_SUCCESS)
export const loginRequestFailure = createAction(
  types.AUTH_LOGIN_REQUEST_FAILURE
)
export const login = recaptcha =>
  fetchHelper(
    `${API_AUTH_ROOT}/login/`,
    {
      method: 'POST',
      headers: {
        'x-recaptcha-token': recaptcha.token,
        'x-recaptcha-version': recaptcha.version,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    loginRequestSuccess,
    loginRequestFailure
  )
export const proxyLogin = fetchHelper(
  `${API_AUTH_ROOT}/proxy_login/`,
  { method: 'POST' },
  loginRequestSuccess,
  loginRequestFailure
)

export const logoutRequestSuccess = createAction(types.AUTH_LOGOUT_SUCCESS)
export const logoutRequestFailure = createAction(types.AUTH_LOGOUT_FAILURE)
export const logout = fetchHelper(
  `${API_AUTH_ROOT}/logout/`,
  { method: 'POST' },
  logoutRequestSuccess,
  logoutRequestFailure
)

export const signupRequestSuccess = createAction(types.AUTH_SIGNUP_SUCCESS)
export const signupRequestFailure = createAction(
  types.AUTH_SIGNUP_REQUEST_FAILURE
)
export const signUp = recaptcha =>
  fetchHelper(
    `${API_AUTH_ROOT}/registration/`,
    {
      method: 'POST',
      headers: {
        'x-recaptcha-token': recaptcha.token,
        'x-recaptcha-version': recaptcha.version,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    signupRequestSuccess,
    signupRequestFailure
  )

export const forgotPasswordRequestSuccess = createAction(
  types.AUTH_FORGOT_PASSWORD_SUCCESS
)
export const forgotPasswordRequestFailure = createAction(
  types.AUTH_FORGOT_PASSWORD_REQUEST_FAILURE
)
export const forgotPassword = fetchHelper(
  `${API_AUTH_ROOT}/password/reset/`,
  { method: 'POST' },
  forgotPasswordRequestSuccess,
  forgotPasswordRequestFailure
)

export const resetPasswordRequestSuccess = createAction(
  types.AUTH_RESET_PASSWORD_SUCCESS
)
export const resetPasswordRequestFailure = createAction(
  types.AUTH_RESET_PASSWORD_REQUEST_FAILURE
)
export const resetPassword = fetchHelper(
  `${API_AUTH_ROOT}/password/reset/confirm/`,
  { method: 'POST' },
  resetPasswordRequestSuccess,
  resetPasswordRequestFailure
)

export const changePasswordRequestSuccess = createAction(
  types.AUTH_CHANGE_PASSWORD_SUCCESS
)
export const changePasswordRequestFailure = createAction(
  types.AUTH_CHANGE_PASSWORD_REQUEST_FAILURE
)
export const changePassword = fetchHelper(
  `${API_AUTH_ROOT}/password/change/`,
  { method: 'POST' },
  changePasswordRequestSuccess,
  changePasswordRequestFailure
)

export const loginWithFacebookRequestSuccess = createAction(
  types.AUTH_FACEBOOK_LOGIN_SUCCESS
)
export const loginWithFacebookRequestFailure = createAction(
  types.AUTH_FACEBOOK_LOGIN_REQUEST_FAILURE
)
export const loginWithFacebook = fetchHelper(
  `${API_AUTH_ROOT}/facebook/`,
  { method: 'POST' },
  loginWithFacebookRequestSuccess,
  loginWithFacebookRequestFailure
)

export const loginWithMicrosoftRequestSuccess = createAction(
  types.AUTH_MICROSOFT_LOGIN_SUCCESS
)
export const loginWithMicrosoftRequestFailure = createAction(
  types.AUTH_MICROSOFT_LOGIN_REQUEST_FAILURE
)
export const loginWithMicrosoft = fetchHelper(
  `${API_AUTH_ROOT}/microsoft/`,
  { method: 'POST' },
  loginWithMicrosoftRequestSuccess,
  loginWithMicrosoftRequestFailure
)

export const invalidAuth = createAction(types.AUTH_INVALID)

export const cancelSubscriptionSuccess = createAction(
  types.AUTH_CANCEL_SUBSCRIPTION_SUCCESS
)
export const cancelSubscriptionFailure = createAction(
  types.AUTH_CANCEL_SUBSCRIPTION_REQUEST_FAILURE
)
export const cancelSubscription = fetchHelper(
  `${API_ROOT}/subscriptions/cancel/`,
  { method: 'POST' },
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure
)

export const addVideoSeen = createAction(types.ADD_VIDEO_SEEN)

export const clearSubscription = createAction(types.REMOVE_SUBSCRIPTION)

export const markSubscriptionCancellationPending = createAction(
  types.SUBSCRIPTION_CANCELLATION_PENDING
)

export const cancelFreeTrialRequestSuccess = createAction(
  types.CANCEL_FREE_TRIAL_SUCCESS
)
export const cancelFreeTrialRequestFailure = createAction(
  types.CANCEL_FREE_TRIAL_REQUEST_FAILURE
)
export const cancelFreeTrial = fetchHelper(
  `${API_ROOT}/subscriptions/cancel_free_trial/`,
  { method: 'PATCH' },
  cancelFreeTrialRequestSuccess,
  cancelFreeTrialRequestFailure
)

export const loginFromEmail = createAction(types.AUTH_FROM_EMAIL)

export const getUserSuccess = createAction(types.AUTH_FETCH_USER_SUCCESS)
export const getUserFailure = createAction(types.AUTH_FETCH_USER_FAILURE)
export const getUser = fetchHelper(
  `${API_AUTH_ROOT}/user/`,
  { method: 'GET' },
  getUserSuccess,
  getUserFailure
)

export const revokeGiftPermission = createAction(types.REVOKE_GIFT_SUBSCRIPTION)

export const revokeFreeTrialPermission = createAction(types.REVOKE_FREE_TRIAL)

export const updateGiftAvailability = createAction(
  types.UPDATE_GIFT_AVAILABILITY
)

export const openAthensLogin = fetchHelper(
  `${API_AUTH_ROOT}/authorization_code_login/`,
  { method: 'POST' },
  loginRequestSuccess,
  loginRequestFailure
)

export const deleteAccountSuccess = createAction(
  types.AUTH_ACCOUNT_DELETE_SUCCESS
)
export const deleteAccountFailure = createAction(
  types.AUTH_ACCOUNT_DELETE_FAILURE
)
export const deleteAccount = fetchHelper(
  `${API_ROOT}/profile/`,
  { method: 'DELETE' },
  deleteAccountSuccess,
  deleteAccountFailure
)
