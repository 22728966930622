import toast from 'react-hot-toast'

const notifications = {
  addressCreated: {
    error: {
      id: 'addressCreatedError',
      message: 'Oops! Failed to create the address. Please try again.'
    },
    success: {
      id: 'addressCreatedSuccess',
      message: 'Address created successfully!'
    }
  },
  addressRemoved: {
    error: {
      id: 'addressRemovedError',
      message: 'Oops! Failed to remove the address. Please try again.'
    },
    success: {
      id: 'addressRemovedSuccess',
      message: 'Address removed successfully!'
    }
  },
  addressUpdated: {
    error: {
      id: 'addressUpdatedError',
      message: 'Oops! Failed to update the address. Please try again.'
    },
    success: {
      id: 'addressUpdatedSuccess',
      message: 'Address updated successfully!'
    }
  },
  addressValid: {
    error: {
      id: 'addressValidError',
      message:
        'Oops! The address you entered is invalid. Please double-check and try again.'
    },
    success: {
      id: 'addressValidSuccess',
      message: 'Success! The address you provided is valid.'
    }
  },
  bookmarkCreated: {
    error: {
      id: 'bookmarkCreatedError',
      message: 'Oops! Failed to create the bookmark. Please try again.'
    },
    success: {
      id: 'bookmarkCreatedSuccess',
      message: 'Bookmark created successfully!'
    }
  },
  bookmarkRemoved: {
    error: {
      id: 'bookmarkRemovedError',
      message: 'Oops! Failed to remove the bookmark. Please try again.'
    },
    success: {
      id: 'bookmarkRemovedSuccess',
      message: 'Bookmark removed successfully!'
    }
  },
  cancelSubscription: {
    error: {
      id: 'cancelSubscriptionError',
      message:
        'Oops! We encountered an error while attempting to cancel your subscription. Please try again.'
    },
    success: {
      id: 'cancelSubscriptionSuccess',
      message: 'Your subscription has been successfully cancelled.'
    }
  },
  captchaVerification: {
    error: {
      id: 'captchaVerificationError',
      message: "Oops! Captcha verification didn't pass. Please try again."
    }
  },
  cartUpdated: {
    error: {
      id: 'cartUpdatedError',
      message:
        'Oops! We encountered an error while updating your cart. Please try again.'
    },
    success: {
      id: 'cartUpdatedSuccess',
      message: 'Success! Your cart has been successfully updated.'
    }
  },
  couponNoLongerValid: {
    error: {
      id: 'couponNoLongerValidError',
      message:
        'Oops! Your applied coupon is no longer available after your cart updated.'
    }
  },
  contestSubmitted: {
    error: {
      id: 'constestSubmittedError',
      message:
        'Oops! Your submission to the contest failed. Please check and try again.'
    },
    success: {
      id: 'constestSubmittedSuccess',
      message: 'Fantastic! Your submission to the contest has been registered.'
    }
  },
  deleteAccount: {
    error: {
      id: 'deleteAccountError',
      message:
        'Oops! We could not delete your account. Please check and try again.'
    },
    success: {
      id: 'deleteAccountSuccess',
      message: 'Account deleted.'
    }
  },
  discountCodeValid: {
    error: {
      id: 'discountCodeValidError',
      message:
        'Oops! The discount code you entered is invalid. Please check and try again.'
    },
    success: {
      id: 'discountCodeValidSuccess',
      message: 'Fantastic! The discount code you entered is valid.'
    }
  },
  emailVerified: {
    error: {
      id: 'emailVerifiedError',
      message: "Oops! We couldn't verify your email address. Please try again."
    },
    success: {
      id: 'emailVerifiedSuccess',
      message: 'Success! Your email has been successfully verified.'
    }
  },
  emptyCart: {
    error: {
      id: 'emptyCartError',
      message: 'Your cart is empty.'
    }
  },
  enabledToGift: {
    success: {
      id: 'enabledToGiftSuccess',
      message: 'Hooray! You are now able to gift a subscription.'
    }
  },
  favoriteAdded: {
    error: {
      id: 'favoriteAddedError',
      message:
        "Oops! We couldn't add the item to your favorites. Please try again."
    },
    success: {
      id: 'favoriteAddedSuccess',
      message: 'Added to favorites! You now have it saved.'
    }
  },
  favoriteRemoved: {
    error: {
      id: 'favoriteRemovedError',
      message:
        "Oops! We couldn't remove the item from your favorites. Please try again."
    },
    success: {
      id: 'favoriteRemovedSuccess',
      message: "Removed from favorites! It's no longer saved."
    }
  },
  forgotPassword: {
    error: {
      id: 'forgotPasswordError',
      message: "Oops! We couldn't process your request. Please try again."
    },
    success: {
      id: 'forgotPasswordSuccess',
      message:
        'Success! An email has been sent to your inbox with further instructions'
    }
  },
  forbiddenToWatch: {
    error: {
      id: 'forbiddenToWatchError',
      message: "Oops! You don't have permission to watch this course."
    }
  },
  freeTrialCancelled: {
    error: {
      id: 'freeTrialCancelledError',
      message: "Oops! We couldn't cancel your free trial. Please try again."
    },
    success: {
      id: 'freeTrialCancelledSuccess',
      message: 'Your free trial has been successfully canceled.'
    }
  },
  fraudProtection: {
    error: {
      id: 'fraudProtectionError',
      message:
        'Oops! Your transaction failed due to fraud protection policies. Please try again.'
    }
  },
  itemRemovedFromCart: {
    error: {
      id: 'itemRemovedFromCartError',
      message:
        "Oops! We couldn't remove the item from your cart. Please try again."
    },
    success: {
      id: 'itemRemovedFromCartSuccess',
      message: 'Success! The item has been removed from your cart.'
    }
  },
  interestsUpdate: {
    error: {
      id: 'interestsUpdateError',
      message:
        'Oops! We encountered an error while updating your interests. Please try again.'
    },
    success: {
      id: 'interestsUpdateSuccess',
      message: 'You successfully updated your interests!'
    }
  },
  funnelConversion: {
    error: {
      id: 'funnelConversionError',
      message: "Oops! We couldn't sign you up. Please try again."
    },
    success: {
      id: 'funnelConversionSuccess',
      message:
        "Thanks for signing up! We've emailed you a link to check out FREE CONTENT from some of our most popular courses."
    }
  },
  giftSubscription: {
    error: {
      id: 'giftSubscriptionError',
      message:
        "Oops! We couldn't deliver your gift subscription. Please try again."
    },
    success: {
      id: 'giftSubscriptionSuccess',
      message:
        'Great! An email has been sent to your recipient with instructions on how to claim your gift'
    }
  },
  login: {
    error: {
      id: 'loginError',
      message:
        'Oops! Login failed. Please double-check your username and password and try again.'
    },
    success: {
      id: 'loginSuccess',
      message: "You're now logged in."
    }
  },
  logout: {
    error: {
      id: 'logoutError',
      message: "Oops! We couldn't log you out. Please try again."
    },
    success: {
      id: 'logoutSuccess',
      message: 'You have been successfully logged out. See you next time!'
    }
  },
  messageSent: {
    error: {
      id: 'messageSentError',
      message: "Oops! We couldn't send your message. Please try again."
    },
    success: {
      id: 'messageSentSuccess',
      message: 'Your message has been successfully sent!'
    }
  },
  orderPlaced: {
    error: {
      id: 'orderPlacedError',
      message: 'Oops! We had trouble placing your order. Please try again.'
    },
    success: {
      id: 'orderPlacedSuccess',
      message: 'Success! Your order has been placed.'
    }
  },
  passwordChange: {
    error: {
      id: 'passwordChangeError',
      message: 'Oops! We had trouble changing your password. Please try again.'
    },
    success: {
      id: 'passwordChangeSuccess',
      message: 'Success! You changed your password.'
    }
  },
  paymentMethodCreated: {
    error: {
      id: 'paymentMethodCreatedError',
      message: 'Oops! Failed to create the payment method. Please try again.'
    },
    success: {
      id: 'paymentMethodCreatedSuccess',
      message: 'Payment method created successfully!'
    }
  },
  paymentMethodDeleted: {
    error: {
      id: 'paymentMethodDeletedError',
      message: 'Oops! Failed to delete the payment method. Please try again.'
    },
    success: {
      id: 'paymentMethodDeletedSuccess',
      message: 'Payment method deleted successfully!'
    }
  },
  paymentMethodSetAsDefault: {
    error: {
      id: 'paymentMethodSetAsDefaultError',
      message:
        'Oops! Failed to set payment method as default. Please try again.'
    },
    success: {
      id: 'paymentMethodSetAsDefaultSuccess',
      message: 'Success! Payment method set as default.'
    }
  },
  profileUpdate: {
    error: {
      id: 'profileUpdateError',
      message:
        'Oops! We encountered an error while updating your profile. Please try again.'
    },
    success: {
      id: 'profileUpdateSuccess',
      message: 'You successfully updated your profile!'
    }
  },
  redeemedGiftedSubscription: {
    error: {
      id: 'redeemedGiftedSubscriptionError',
      message:
        "Oops! We couldn't redeem your Gifted Subscription. Please try again."
    },
    success: {
      id: 'redeemedGiftedSubscriptionSuccess',
      message: 'Success! Enjoy your Gifted Subscription.'
    }
  },
  resendVerifyEmail: {
    error: {
      id: 'resendVerifyEmailError',
      message:
        "Oops! We couldn't resend the verification email. Please try again."
    },
    success: {
      id: 'resendVerifyEmailSuccess',
      message:
        'Success! The verification email has been sent. Please check your inbox.'
    }
  },
  seatReserved: {
    error: {
      id: 'seatReservedError',
      message: 'Oops! Failed to reserve the seat. Please try again.'
    },
    success: {
      id: 'seatReservedSuccess',
      message: 'Seat reserved successfully!'
    }
  },
  signup: {
    error: {
      id: 'signupError',
      message: 'Oops! Sign-up failed. Please try again.'
    },
    success: {
      id: 'signupSuccess',
      message: 'Welcome to Stan Winston School of Character Arts!'
    }
  },
  subscribe: {
    error: {
      id: 'subscribeError',
      message: 'Oops! Your subscription failed. Please try again.'
    },
    success: {
      id: 'subscribeSuccess',
      message: 'Success! You have been subscribed.'
    }
  },
  unableToPurchase: {
    error: {
      id: 'unableToPurchaseError',
      message: 'Oops! You are not allowed to make purchases.'
    }
  },
  upgradeSubscription: {
    error: {
      id: 'upgradeSubscriptionError',
      message: "Oops! We couldn't upgrade your subscription. Please try again."
    },
    success: {
      id: 'upgradeSubscriptionSuccess',
      message: 'Success! Your subscription has been upgraded.'
    }
  },
  unupgradeableSubscription: {
    error: {
      id: 'unupgradeableSubscriptionError',
      message: 'Oops! Your account does not allow upgrading.'
    }
  },
  userImpersonated: {
    error: {
      id: 'userImpersonatedError',
      message: "Oops! We couldn't impersonate the user. Please try again."
    },
    success: {
      id: 'userImpersonatedSuccess',
      message: 'Success! You are now impersonating the user.'
    }
  },
  userReleased: {
    error: {
      id: 'userReleasedError',
      message: "Oops! We couldn't release the user. Please try again."
    },
    success: {
      id: 'userReleasedSuccess',
      message: 'The user has been successfully released.'
    }
  },
  tutorialUserInfo: {
    error: {
      id: 'tutorialUserInfoError',
      message:
        "Oops! We couldn't retrieve the user data for this tutorial. Please try again."
    }
  },
  recaptcha: {
    error: {
      id: 'recaptchaError',
      message:
        "Oops! We weren't able to automatically validate you as a human, please check the box to confirm."
    }
  }
}

const genericNotification = {
  success: {
    message: 'Success',
    id: 'genericSuccess'
  },
  error: {
    message: 'Oops! There has been an error',
    id: 'genericError'
  }
}

export const toastSuccess = notification => {
  // Fallback to generic messaging if custom one is not available
  const finalNotification = notification?.success || genericNotification.success

  return toast.success(finalNotification.message, {
    id: finalNotification.id || finalNotification.message
  })
}

export const toastError = notification => {
  // Fallback to generic messaging if custom one is not available
  const finalNotification = notification?.error || genericNotification.error
  return toast.error(finalNotification.message, {
    id: finalNotification.id || finalNotification.message
  })
}

export const toastServerError = error => {
  const notification = error || genericNotification.error.message
  return toast.error(notification, {
    id: notification.id || notification
  })
}
export default notifications
