/* global DISPLAY_DATE_FORMAT */

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Rodal from 'rodal'

import { deleteAccountDispatch as dispatcher } from 'reduxModules/auth/dispatchers'
import useEventListener from '../../../utils/useEventListener'

import 'rodal/lib/rodal.css'
import './styles.css'

function DeleteAccount({ isMobile, deleteAccountAction }) {
  const [isDialogVisible, setDialogVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false) // Track loading state

  const onOpenDialog = () => setDialogVisible(true)
  const onCloseDialog = () => setDialogVisible(false)

  const handleInputChange = e => {
    const value = e.target.value
    setInputValue(value)
    setButtonDisabled(value !== 'DELETE') // Enable button only if input matches "DELETE"
  }

  const handleDelete = async () => {
    setIsLoading(true) // Show loading spinner
    deleteAccountAction()
  }

  // Handler to dismiss dialog when hitting 'Escape'
  const escapeHandler = e => {
    if (e.key === 'Escape' && isDialogVisible) onCloseDialog()
  }

  // Attach event listener
  useEventListener('keydown', escapeHandler)

  return (
    <div className="account-section" id="DeleteAccount">
      <h3 className="account-section__title">Delete Your Account</h3>
      <button
        type="button"
        className="submit-btn btn -orange -mini danger"
        onClick={onOpenDialog}
      >
        Delete Your Account
      </button>
      <div id="DeleteAccountForm">
        <Rodal
          visible={isDialogVisible}
          onClose={onCloseDialog}
          width={isMobile ? 375 : 800}
          height={380}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <h2 className="title">Delete Your Account?</h2>
            <p>
              Are you sure you want to delete your account? This action is
              permanent and cannot be undone.{' '}
              <b>
                All your data will be permanently removed, and you will lose
                access to your account.
              </b>
            </p>
            <p>To confirm, please type DELETE in the box below:</p>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type 'DELETE' to confirm"
            />
            <div className="action">
              <button
                onClick={handleDelete}
                disabled={isButtonDisabled || isLoading} // Disable button while loading
                className="submit-btn btn -orange -mini danger"
                type="button"
              >
                {isLoading ? 'Processing...' : 'Delete Account'}
              </button>
            </div>
          </div>
        </Rodal>
      </div>
    </div>
  )
}

DeleteAccount.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default connect(null, dispatcher)(DeleteAccount)
